export default {
  cake: {
    369: '0xC23c5BD2904d590e9b3eE4743B29cd9ffDD8655A' // XXXXCORN
  },
  masterChef: {
    369: '0xc76b9E2CFfAd163E43b077db806EB448B7aF8bC3', // XXXXMASTERCHEF
  },
  weth: {
    369: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  multiCall: {
    369: '0xf9c9CCDf6a298649e179e1aEC6942DB6d0a5c1e5' // XXXXMULTICALL 0x594052B0A068Ec1F242dD71872cc69fEcB8F6840
  },
  usdc: {
    369: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664'
  },
  usdt: {
    369: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'
  },
  wavax: {
    369: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
  },
  avax: {
    369: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
  },
}
