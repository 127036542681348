import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [


  {
    pid: 12,
    risk: 5,
    lpSymbol: 'DENIS-WETH LP',
    otherExchange: 'DYORSWAP',
    decimal: 18,
    lpAddresses: {
      369: '' // XXXXCORN-WAVAX
    },
    tokenSymbol: 'AR',
    tokenAddresses: {
      369: '' // XXXXCORN
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },

  {
    pid: 14,
    risk: 5,
    lpSymbol: 'DENIS-DAI LP',
    otherExchange: 'DYORSWAP',
    decimal: 18,
    lpAddresses: {
      369: '' // XXXXCORN-WAVAX
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      369: '' // XXXXCORN
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'USDT-USDC LP',
    otherExchange: 'DYORSWAP',
    decimal: 18,
    lpAddresses: {
      369: '' // XXXXCORN-WAVAX
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      369: '' // XXXXCORN
    },
    quoteTokenSymbol: QuoteToken.WAVAX,
    quoteTokenAdresses: contracts.wavax,
  },


    {
    pid: 10,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'DENIS',
    decimal: 18,
    lpAddresses: {
      369: '' // XXXXCORN-USDC
    },
    tokenSymbol: 'DENIS',
    tokenAddresses: {
      369: '' // XXXXCORN
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },



  {
    pid: 1,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    decimal: 18,
    lpAddresses: {
      369: ''
    },
    tokenSymbol: 'WETH.e',
    tokenAddresses: {
      369: ''
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },


  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    decimal: 18,
    lpAddresses: {
      369: ''
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      369: ''
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

]

export default farms
