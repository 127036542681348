import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
   {
    label: 'PRESALE LIVE HERE!',
    icon: 'TicketIcon',
    href: '/presale',
  }, 
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: '/',
      },
      {
        label: 'Liquidity',
        href: '/'
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
 //    {
 //   label: 'Lottery',
 //   icon: 'TicketIcon',
 //   href: '/lottery',
 // }, 
  {
     label: 'Dividends', // XXXXSOON
     icon: 'BondsIcon',
     href: '/dividends',
  },
  {
     label: 'NFT Collection', // XXXXSOON
     icon: 'NftIcon',
     href: '/nft',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'DexScreener',
        href: 'https://dexscreener.com/', // XXXXCORN
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://denis-finance.gitbook.io/denis-finance/', // XXXXGITBOOK
      }, 
    ],
  },
  /* {
    label: 'Tools',
    icon: 'NftIcon',
    items: [
      {
        label: 'VFAT Tools',
        href: 'https://vfat.tools/polygon/cornyswap/',
      },
      {
        label: 'PolyPup UI',
        href: 'https://ui.polypup.finance/address/',
      },
    ],
  }, */
   {
    label: 'Audits/Checks',
    icon: 'AuditIcon',
    items: [
      {
        label: 'SOON',
        href: '/',
      },
    ],
  }, 
]
export default config
